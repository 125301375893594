// Main imports
import { Routes, Route, Navigate } from "react-router-dom";
import { AccessibilityWidget } from 'react-accessibility'

// Views and components
import Footer from "./components/ui/Footer/Footer";
import Home from "./views/Home/Home";
import Locations from "./views/Locations/Locations";

// Data and context
import restaurantData from "./data/restaurantData.json";
import WebplaceProvider from "./store/webplace-provider";

// Utilities
import "./App.css";

// GET INDEX OF NAVBAR OBJECT
const findObject = (arr, objName) => {
  const object = arr.findIndex((item) => item.title === objName);
  return object;
};

const navbarData = restaurantData.navbar_items.options;

const App = () => {
  return (
    <WebplaceProvider>
      <Routes>
        <Route path="/" element={<Home />} />

        {/* Show and redirect to Locations */}
        {navbarData[findObject(navbarData, "Locations")].shown && (
          <Route
            path={navbarData[findObject(navbarData, "Locations")].url}
            element={<Locations />}
          />
        )}

        <Route path="*" element={<Navigate to="/" replace />} />
      </Routes>
      <AccessibilityWidget />
      <Footer />
    </WebplaceProvider>
  );
};

export default App;
